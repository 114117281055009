import * as base64js from 'base64-js';
var decodeRegex = /[._-]/g;
var decodeChar = function (c) {
    switch (c) {
        case '.': return '=';
        case '-': return '+';
        case '_': return '/';
    }
};
function utf8Decode(bytes) {
    var chars = [], length = bytes.length;
    var offset = 0;
    while (offset < length) {
        var c = bytes[offset];
        var c2 = bytes[offset + 1];
        var c3 = bytes[offset + 2];
        if (c <= 128) {
            chars.push(String.fromCharCode(c));
            offset += 1;
        }
        else if (191 < c && c < 224) {
            chars.push(String.fromCharCode(((c & 31) << 6) | (c2 & 63)));
            offset += 2;
        }
        else {
            chars.push(String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)));
            offset += 3;
        }
    }
    return chars.join('');
}
function decode(data) {
    while (data.length % 4 !== 0) {
        data += '=';
    }
    return utf8Decode(base64js.toByteArray(data));
}
var decodeUrlSafeBase64 = function (base64String) { return decode(base64String.replace(decodeRegex, decodeChar)); };
export { decodeUrlSafeBase64 };
