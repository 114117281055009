import * as $ from 'jquery';
import * as EmailValidator from 'email-validator';
import { decodeUrlSafeBase64 } from './base64';
require('../styles/main.less');
require('css.escape');
var dummyArtist = require('../images/dummy-40px.jpg');
var templates = {
    widget_general: require('../templates/widget-general.mustache'),
    widget_general_multi: require('../templates/widget-general-multi.mustache'),
    success_general: require('../templates/success-general.mustache'),
    doi_sent_general: require('../templates/doi-sent-general.mustache'),
    permitted_general: require('../templates/permitted-general.mustache'),
    widget_checkbox: require('../templates/widget-checkbox.mustache'),
    widget_category: require('../templates/widget-category.mustache'),
    widget_sign_off_from_all: require('../templates/widget-sign-off-from-all.mustache'),
    widget_ticketalarm: require('../templates/widget-ticketalarm.mustache'),
    widget_ticketalarm_result: require('../templates/widget-ticketalarm-result.mustache'),
    widget_ticketalarm_tag: require('../templates/widget-ticketalarm-tag.mustache'),
    success_ticketalarm: require('../templates/success-ticketalarm.mustache'),
    doi_sent_ticketalarm: require('../templates/doi-sent-ticketalarm.mustache'),
    error_ticketalarm: require('../templates/error-ticketalarm.mustache'),
    error_general: require('../templates/error-general.mustache'),
    widget_blacklist: require('../templates/widget-blacklist-cockpit.mustache'),
    widget_blacklist_entry: require('../templates/widget-blacklist-entry.mustache'),
    widget_blacklist_feature: require('../templates/widget-blacklist-feature.mustache')
};
/*
 * Maps rule.template and rule.status to templates; access via widgets[rule.template][rule.status]
 */
var widgets = {
    GENERAL_NEWSLETTER: {
        WANT_PERMISSION: 'widget_general',
        HAVE_PERMISSION: 'permitted_general',
        success: 'success_general',
        doi_sent: 'doi_sent_general',
        error: 'error_general'
    },
    TICKET_ALARM: {
        WANT_PERMISSION: 'widget_ticketalarm',
        HAVE_PERMISSION: 'widget_ticketalarm',
        success: 'success_ticketalarm',
        doi_sent: 'doi_sent_ticketalarm',
        error: 'error_ticketalarm'
    },
    TICKET_ALARM_SINGLE: {
        WANT_PERMISSION: 'widget_general',
        HAVE_PERMISSION: 'permitted_general',
        success: 'success_general',
        doi_sent: 'doi_sent_general',
        error: 'error_general'
    },
    CHECKBOX: {
        WANT_PERMISSION: 'widget_checkbox'
    },
    GENERAL_NEWSLETTER_COCKPIT: {
        WANT_PERMISSION: 'widget_general',
        HAVE_PERMISSION: 'widget_general'
    },
    GENERAL_NEWSLETTER_COCKPIT_MULTI: {
        WANT_PERMISSION: 'widget_general_multi',
        HAVE_PERMISSION: 'widget_general_multi'
    },
    TICKET_ALARM_COCKPIT: {
        WANT_PERMISSION: 'widget_ticketalarm',
        HAVE_PERMISSION: 'widget_ticketalarm'
    },
    CATEGORY_NEWSLETTER_COCKPIT: {
        WANT_PERMISSION: 'widget_category',
        HAVE_PERMISSION: 'widget_category'
    },
    SIGN_OFF_FROM_ALL: {
        WANT_PERMISSION: 'widget_sign_off_from_all',
        HAVE_PERMISSION: 'widget_sign_off_from_all'
    },
    BLACKLIST_COCKPIT: {
        WANT_PERMISSION: 'widget_blacklist',
        HAVE_PERMISSION: 'widget_blacklist'
    }
};
var render = function (name, data) {
    var template = templates[name];
    return template(data);
};
$('body').on('click', function () {
    $('.evi-widget-artists-result').empty();
});
var EviWidget = function (self, options) {
    var getRules = function () {
        var options = getOptions();
        $.ajax({
            url: options.baseURL + "/public/rule",
            data: {
                'evi-token': options.token,
                'widget-version': VERSION
            },
            dataType: 'json',
            success: renderWidget,
            error: handleError
        });
    };
    var extendBlacklistWidget = function (widget, rule) {
        $.ajax({
            url: options.baseURL + "/public/blacklist",
            data: {
                'evi-token': options.token,
                'widget-version': VERSION
            },
            dataType: 'json',
            success: function (data) {
                rule.blacklistData = data.filter(function (e) { return e.status === 'ACTIVE' && e.type === 'EMAIL'; })
                    .reduce(function (previous, current) { return current; }, null);
                var blacklisted = rule.blacklistData !== null;
                if (blacklisted) {
                    rule.blacklistData.lastModified = toLocaleDate(rule.blacklistData.lastModified);
                }
                widget.find('.evi-blacklist-ontainer').append(render(blacklisted ? 'widget_blacklist_entry' : 'widget_blacklist_feature', rule));
                var checkbox = widget.find('input.evi-widget-checkbox');
                checkbox.prop('checked', blacklisted);
                checkbox.on('click', function () {
                    blockOrUnblockEmailFeature(rule, blacklisted ? 'DELETE' : 'POST');
                });
            },
            error: handleError
        });
    };
    var blockOrUnblockEmailFeature = function (rule, method) {
        $.ajax({
            url: options.baseURL + "/public/blacklist/EMAIL",
            type: method,
            data: {
                'evi-token': options.token,
                'widget-version': VERSION
            },
            dataType: 'json',
            success: function () {
                renderRule(rule);
            },
            error: handleError
        });
    };
    var createPermissionLink = function (context, requestOptions) {
        updatePermissionLink(context, 'POST', requestOptions);
    };
    var revokePermissionLink = function (context, requestOptions) {
        updatePermissionLink(context, 'DELETE', requestOptions);
    };
    var resendPermissionLink = function (context, requestOptions) {
        updatePermissionLink(context, 'POST', requestOptions, '/public/permission-link/resend');
    };
    var updatePermissionLink = function (context, method, requestOptions, url) {
        if (url === void 0) { url = '/public/permission-link'; }
        var options = getOptions();
        var postData = {
            eviConfigToken: options.token,
            revision: getRevision(),
            context: context.context,
            widgetVersion: VERSION
        };
        if (context.rule) {
            postData.ruleIds = [context.rule.id];
        }
        if (context.rules) {
            postData.ruleIds = context.rules.map(function (rule) { return rule.id; });
        }
        if (context.identityFeatureValue) {
            postData.identityFeatureValue = context.identityFeatureValue;
        }
        if (context.actionId) {
            postData.actionId = context.actionId;
        }
        var settings = {
            type: method,
            url: "" + options.baseURL + url,
            data: JSON.stringify(postData),
            contentType: 'application/json; charset=utf-8',
            error: function () {
                if (requestOptions && requestOptions.error) {
                    requestOptions.error();
                }
                handleError();
            },
            context: context
        };
        if (requestOptions && requestOptions.success) {
            settings.success = function (response) { return requestOptions.success(response); };
        }
        if (requestOptions && requestOptions.timeout) {
            settings.timeout = requestOptions.timeout;
        }
        $.ajax(settings);
    };
    var isMultiArtistTicketAlarm = function (rule) {
        return rule.template === 'TICKET_ALARM' || rule.template === 'TICKET_ALARM_COCKPIT';
    };
    var getOptions = function () {
        return self.data('options');
    };
    var getRevision = function () {
        return self.data('revision');
    };
    var getEmailInput = function (rule) {
        return getWidget(rule).find('input[name="email"]');
    };
    var getArtistsInput = function (rule) {
        return getWidget(rule).find('input[name="search"]');
    };
    var getWidget = function (rule) {
        return $("#evi-widget-rule-" + rule.id);
    };
    var validate = function (context) {
        var isValid = true;
        var emailInput = getEmailInput(context.rule);
        if (emailInput.length) {
            var email = emailInput.val().trim();
            var form = getWidget(context.rule).find('form');
            if (EmailValidator.validate(email)) {
                context.identityFeatureValue = email;
                context.rule.email = email;
                emailInput.removeClass('evi-widget-input-error');
                form.find(".evi-widget-form-error").hide();
            }
            else {
                isValid = false;
                emailInput.addClass('evi-widget-input-error');
                form.find(".evi-widget-form-error").show();
                form.find(".evi-widget-form-error li").hide();
                if (email === '') {
                    form.find(".evi-widget-email-error-empty").show();
                }
                else {
                    form.find(".evi-widget-email-error-format").show();
                }
            }
        }
        var widget = getWidget(context.rule);
        var consentRequired = widget.find('.evi-widget-consent-required');
        if (consentRequired.is(':visible:not(:checked)')) {
            isValid = false;
            consentRequired.addClass('evi-widget-input-error');
            widget.find(".evi-widget-consent-error").show();
            widget.find("li.evi-widget-consent-error").parent().show();
        }
        else {
            consentRequired.removeClass('evi-widget-input-error');
            widget.find(".evi-widget-consent-error").hide();
            widget.find("li.evi-widget-consent-error").parent().hide();
        }
        if (isMultiArtistTicketAlarm(context.rule)) {
            var artistSearch = getWidget(context.rule).find('.evi-widget-artists-search');
            var artistList = getWidget(context.rule).find('.evi-widget-artist-list:visible');
            var artistIds_1 = [];
            var artistNames_1 = [];
            artistList.find('li[data-artist-id]').each(function (_, element) {
                artistIds_1.push(element.getAttribute('data-artist-id'));
                artistNames_1.push(element.getAttribute('data-name'));
            });
            if (artistIds_1 && artistIds_1.length > 0) {
                context.context = { artistID: artistIds_1.join(',') };
                context.rule.artistNames = artistNames_1;
                artistSearch.removeClass('evi-widget-input-error');
                artistSearch.parent().find(".evi-widget-artists-error").hide();
            }
            else {
                isValid = false;
                artistSearch.addClass('evi-widget-input-error');
                artistSearch.parent().find(".evi-widget-artists-error").show();
            }
        }
        return isValid;
    };
    var handleError = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var optionsError = getOptions().error;
        if (typeof optionsError === 'function') {
            optionsError(args);
        }
    };
    var parseToken = function (options) {
        var segments = options.token.split('.');
        return {
            payload: JSON.parse(decodeUrlSafeBase64(segments[1]))
        };
    };
    var getEmailFromPayload = function (payload) {
        var result;
        if (payload.identityFeatures) {
            payload.identityFeatures.forEach(function (identityFeature) {
                if (identityFeature.type === 'EMAIL') {
                    result = identityFeature.value;
                }
            });
        }
        // noinspection JSUnusedAssignment -> returns undefined if no email address has been found in payload
        return result;
    };
    var selectArtist = function (e, rule) {
        var target = $(e.delegateTarget);
        var artist = { artistId: target.data('artist-id'), name: target.data('name') };
        addArtist(rule, artist);
        var input = target.closest('.evi-widget-artists').find('input');
        input.val('');
    };
    var addArtist = function (rule, artist) {
        var widget = getWidget(rule);
        widget.addClass('evi-widget-artist-selected');
        widget.removeClass('evi-widget-last-artist-removed');
        var artistLists = widget.find('.evi-widget-artist-list');
        var artistAdded = false;
        artistLists.filter(':visible').first().each(function (i, artistList) {
            artistAdded = addArtistToList(rule, $(artistList), artist, true) || artistAdded;
        });
    };
    var getLastModifiedForTypeAndValue = function (actions, type, value) {
        var result = null;
        if (actions) {
            actions.forEach(function (action) {
                if (action.type === type && action.permissionLinks) {
                    action.permissionLinks.forEach(function (permissionLink) {
                        if (permissionLink.value === value) {
                            result = permissionLink.lastModified;
                        }
                    });
                }
            });
        }
        return result;
    };
    var getLastModifiedByForTypeAndValue = function (actions, type, value) {
        var result = null;
        if (actions) {
            actions.forEach(function (action) {
                if (action.type === type && action.permissionLinks) {
                    action.permissionLinks.forEach(function (permissionLink) {
                        if (permissionLink.value === value) {
                            result = permissionLink.lastModifiedBy;
                        }
                    });
                }
            });
        }
        return result;
    };
    var toLocaleDate = function (date) {
        return date ? new Date(date).toLocaleString() : '-';
    };
    var getOriginForTypeAndValue = function (actions, type, value) {
        var result = [];
        if (actions) {
            actions.forEach(function (action) {
                if (action.type === type && action.permissionLinks) {
                    action.permissionLinks.forEach(function (permissionLink) {
                        if (permissionLink.value === value && permissionLink.origin) {
                            result.push(permissionLink.origin);
                        }
                    });
                }
            });
        }
        return result.join(', ');
    };
    var getAttributeForTypeAndValue = function (attribute, actions, type, value) {
        var result = [];
        if (actions) {
            actions.forEach(function (action) {
                if (action.type === type && action.permissionLinks) {
                    action.permissionLinks.forEach(function (permissionLink) {
                        if (permissionLink.value === value && permissionLink[attribute]) {
                            result.push(toLocaleDate(permissionLink[attribute]));
                        }
                    });
                }
            });
        }
        return result.join(', ');
    };
    var getPendingForTypeAndValue = function (actions, type, value) {
        var result = false;
        if (actions) {
            actions.forEach(function (action) {
                if (action.type === type && action.permissionLinks) {
                    action.permissionLinks.forEach(function (permissionLink) {
                        if (permissionLink.value === value && permissionLink.status === 'PENDING') {
                            result = true;
                        }
                    });
                }
            });
        }
        return result;
    };
    var addArtistToList = function (rule, artistList, artist, isNew) {
        if (isNew === void 0) { isNew = false; }
        var existingItems = artistList.find("[data-artist-id=" + artist.artistId + "]");
        if (existingItems.length > 0) {
            existingItems.addClass('evi-widget-artist-blink');
            return false;
        }
        else {
            var widget_1 = artistList.closest('.evi-widget');
            artist.lastModified = toLocaleDate(getLastModifiedForTypeAndValue(rule.actions, 'ARTIST', artist.artistId));
            artist.lastModifiedBy = getLastModifiedByForTypeAndValue(rule.actions, 'ARTIST', artist.artistId);
            artist.origin = getOriginForTypeAndValue(rule.actions, 'ARTIST', artist.artistId);
            artist.confirmed = getAttributeForTypeAndValue('confirmed', rule.actions, 'ARTIST', artist.artistId);
            artist.granted = getAttributeForTypeAndValue('granted', rule.actions, 'ARTIST', artist.artistId);
            artist.revoked = getAttributeForTypeAndValue('revoked', rule.actions, 'ARTIST', artist.artistId);
            artistList.append(render('widget_ticketalarm_tag', artist));
            widget_1.removeClass('evi-widget-last-artist-removed');
            var last_1 = artistList.find('li:last');
            if (isNew && isDoubleOptInEnabled(rule)) {
                last_1.addClass('evi-widget-artist-pending');
            }
            last_1.find('.evi-widget-close').on('click', function (e) {
                var artistId = $(e.delegateTarget).parent().data('artist-id');
                if (last_1.closest('.evi-widget-artist-list-result').length && rule.email) {
                    revokePermissionLink({
                        rule: rule,
                        context: { artistID: artistId }
                    }, {
                        error: function () {
                            addArtistToList(rule, artistList, artist);
                        }
                    });
                }
                removeArtist(widget_1, $(e.delegateTarget).closest('.evi-widget-artist-list'), artistId);
                hideResendPermissionLink(widget_1, '.evi-widget-artist-pending');
            });
            last_1.on('animationend', function (e) {
                $(e.delegateTarget).removeClass('evi-widget-artist-blink');
            });
            widget_1.addClass('evi-widget-artist-selected');
            return true;
        }
    };
    var removeArtist = function (widget, artistList, artistId) {
        artistList.find("[data-artist-id=\"" + CSS.escape(artistId) + "\"]").remove();
        if (artistList.find('li').length === 0) {
            widget.addClass('evi-widget-last-artist-removed');
        }
    };
    var renderArtists = function (rule, response, query) {
        var widget = getWidget(rule);
        var results = widget.find('.evi-widget-artists-result');
        var options = getOptions();
        if (response && response.length > 0) {
            response.forEach(function (artist) {
                if (artist.thumbnailUri) {
                    if (options.artistThumbnailUriBase && !/^(https?:)?\/\/.+/.test(artist.thumbnailUri)) {
                        artist.thumbnailUri = options.artistThumbnailUriBase + artist.thumbnailUri;
                    }
                }
                else {
                    artist.thumbnailUri = dummyArtist;
                }
                artist.highlightedName = artist.name.replace(new RegExp("(" + query + ")", 'gi'), '<strong>$1</strong>');
            });
            results.html(render('widget_ticketalarm_result', { result: response }));
            var items = results.find('li');
            items.first().addClass('evi-widget-artists-result-selection');
            items.on('click', function (e) { return selectArtist(e, rule); }).on('mouseover', function (e) {
                var target = $(e.delegateTarget);
                target.siblings().removeClass('evi-widget-artists-result-selection');
                target.addClass('evi-widget-artists-result-selection');
            });
        }
        else {
            results.empty();
        }
    };
    var searchArtists = function (e, rule) {
        var input = $(e.delegateTarget);
        var widget = input.closest('.evi-widget');
        if (e.which === 27) { // ESC
            renderArtists(rule);
        }
        else if (e.which === 13) { // ENTER
            widget.find('.evi-widget-artists-result-selection').trigger('click');
        }
        else if (e.which === 40) { // CURSOR DOWN
            var selected = widget.find('.evi-widget-artists-result-selection');
            var next = selected.next();
            if (next.length === 0) {
                next = selected.siblings().first();
            }
            if (next.length > 0) {
                next.addClass('evi-widget-artists-result-selection');
                selected.removeClass('evi-widget-artists-result-selection');
            }
        }
        else if (e.which === 38) { // CURSOR UP
            var selected = widget.find('.evi-widget-artists-result-selection');
            var prev = selected.prev();
            if (prev.length === 0) {
                prev = selected.siblings().last();
            }
            if (prev.length > 0) {
                prev.addClass('evi-widget-artists-result-selection');
                selected.removeClass('evi-widget-artists-result-selection');
            }
        }
        else {
            var query_1 = input.val();
            if (query_1.length > 1) {
                var options_1 = getOptions();
                $.ajax({
                    url: options_1.artistBaseURL + "/artists/suggest",
                    data: {
                        'q': query_1,
                        'systemId': options_1.tenant.systemId,
                        'platformId': options_1.tenant.platformId
                    },
                    dataType: 'json',
                    success: function (response) {
                        renderArtists(rule, response, query_1);
                    },
                    error: handleError
                });
            }
            else {
                renderArtists(rule);
            }
        }
    };
    var addArtists = function (rule, artists) {
        if (artists) {
            var widget_2 = getWidget(rule);
            var artistList_1 = widget_2.find('.evi-widget-artist-collection .evi-widget-artist-list');
            artists.forEach(function (artist) {
                var pending = getPendingForTypeAndValue(rule.actions, 'ARTIST', artist.artistId);
                artist.sortName = (pending ? "B" : "A") + artist.name.trim().replace(/[^A-Za-z0-9]/, '');
            });
            artists.sort(function (a, b) {
                return a.sortName.localeCompare(b.sortName);
            }).forEach(function (artist) {
                addArtistToList(rule, artistList_1, artist);
            });
            if (rule.actions) {
                rule.actions.forEach(function (action) {
                    if (action.type === 'ARTIST' && action.permissionLinks) {
                        action.permissionLinks.forEach(function (permissionLink) {
                            if (permissionLink.status === 'PENDING') {
                                widget_2.find('li[data-artist-id="' + permissionLink.value + '"]').addClass('evi-widget-artist-pending');
                            }
                        });
                    }
                });
            }
        }
    };
    var getArtists = function (rule, artistIds) {
        if (artistIds && artistIds.length > 0) {
            var options_2 = getOptions();
            $.ajax({
                url: options_2.artistBaseURL + "/artists",
                data: {
                    'artistIds': artistIds.join(','),
                    'systemId': options_2.tenant.systemId,
                    'platformId': options_2.tenant.platformId
                },
                dataType: 'json',
                success: function (response) { return addArtists(rule, response); },
                error: handleError
            });
        }
    };
    var computeLastModified = function (rule) {
        if (rule.actions) {
            rule.actions.forEach(function (action) {
                if (action.permissionLinks) {
                    action.permissionLinks.forEach(function (permissionLink) {
                        var lastModified = permissionLink.lastModified;
                        if (!rule.lastModified || rule.lastModified > lastModified) {
                            rule.lastModified = lastModified;
                        }
                        if (!action.lastModified || action.lastModified > lastModified) {
                            action.lastModified = lastModified;
                        }
                    });
                    action.lastModified = toLocaleDate(action.lastModified);
                }
            });
            rule.lastModified = toLocaleDate(rule.lastModified);
        }
    };
    var computeLastModifiedBy = function (rule) {
        if (rule.actions) {
            rule.lastModifiedBy = [];
            rule.actions.forEach(function (action) {
                if (action.permissionLinks) {
                    action.lastModifiedBy = [];
                    action.permissionLinks.forEach(function (permissionLink) {
                        if (permissionLink.lastModifiedBy) {
                            if (rule.lastModifiedBy.indexOf(permissionLink.lastModifiedBy) < 0) {
                                rule.lastModifiedBy.push(permissionLink.lastModifiedBy);
                            }
                            if (action.lastModifiedBy.indexOf(permissionLink.lastModifiedBy) < 0) {
                                action.lastModifiedBy.push(permissionLink.lastModifiedBy);
                            }
                        }
                    });
                    action.lastModifiedBy = action.lastModifiedBy.join(', ');
                }
            });
            rule.lastModifiedBy = rule.lastModifiedBy.join(', ');
        }
    };
    var computeOrigin = function (rule) {
        if (rule.actions) {
            rule.origins = [];
            rule.actions.forEach(function (action) {
                if (action.permissionLinks) {
                    action.origins = [];
                    action.permissionLinks.forEach(function (permissionLink) {
                        if (permissionLink.origin) {
                            if (rule.origins.indexOf(permissionLink.origin) < 0) {
                                rule.origins.push(permissionLink.origin);
                            }
                            if (action.origins.indexOf(permissionLink.origin) < 0) {
                                action.origins.push(permissionLink.origin);
                            }
                        }
                    });
                    action.origin = action.origins.join(', ');
                }
            });
            rule.origin = rule.origins.join(', ');
        }
    };
    var computeAttribute = function (attribute, rule) {
        if (rule.actions) {
            var ruleResult_1 = [];
            rule.actions.forEach(function (action) {
                if (action.permissionLinks) {
                    var actionResult_1 = [];
                    action.permissionLinks.forEach(function (permissionLink) {
                        if (permissionLink[attribute]) {
                            if (ruleResult_1.indexOf(permissionLink[attribute]) < 0) {
                                ruleResult_1.push(toLocaleDate(permissionLink[attribute]));
                            }
                            if (actionResult_1.indexOf(permissionLink[attribute]) < 0) {
                                actionResult_1.push(toLocaleDate(permissionLink[attribute]));
                            }
                        }
                    });
                    action[attribute] = actionResult_1.join(', ');
                }
            });
            rule[attribute] = ruleResult_1.join(', ');
        }
    };
    var computePending = function (rule) {
        rule.pending = false;
        if (rule.actions) {
            rule.actions.forEach(function (action) {
                action.pending = false;
                if (action.permissionLinks) {
                    action.permissionLinks.forEach(function (permissionLink) {
                        if (permissionLink.status === 'PENDING') {
                            rule.pending = true;
                            action.pending = true;
                        }
                    });
                }
            });
        }
    };
    var isDoubleOptInEnabled = function (rule, actionId) {
        if (actionId === void 0) { actionId = undefined; }
        var result = rule.doubleOptInType && rule.doubleOptInType !== 'NONE';
        if (result && actionId && rule.actions) {
            var action = rule.actions.find(function (action) { return action.id === actionId; });
            result = result && action.doubleOptIn;
        }
        return result;
    };
    var isBlacklisted = function (rule) {
        return rule && rule.blacklisted && rule.blacklisted.email;
    };
    var hasPendingPermissionLinks = function (rule) {
        return Array.prototype.concat.apply([], rule.actions
            .map(function (action) { return action.permissionLinks; }))
            .filter(function (link) { return link.status === 'PENDING'; }).length > 0;
    };
    var shouldShowResendLink = function (rule, immediately) {
        if (immediately) {
            return isDoubleOptInEnabled(rule);
        }
        else {
            return hasPendingPermissionLinks(rule);
        }
    };
    var renderResendLink = function (widget, rule, immediately) {
        if (immediately === void 0) { immediately = false; }
        if (shouldShowResendLink(rule, immediately)) {
            showResendPermissionLink(widget);
            var $resendLink = widget.find('.evi-widget-resend-permission-link a');
            $resendLink.on('click', function (e) {
                e.preventDefault();
                showSuccessResendPermissionLink(widget);
                resendPermissionLink({ rule: rule });
            });
        }
    };
    var showSuccessResendPermissionLink = function (widget) {
        var $resendLink = widget.find('.evi-widget-resend-permission-link');
        var $resendSuccess = widget.find('.evi-widget-resend-permission-link-success');
        $resendLink.addClass('evi-widget-hidden');
        $resendSuccess.removeClass('evi-widget-hidden');
    };
    var hideResendPermissionLink = function (widget, selector) {
        if (selector === void 0) { selector = '.evi-widget-switch-pending'; }
        if (!widget.find(selector).length) {
            widget.find('.evi-widget-resend-permission-link').addClass('evi-widget-hidden');
            widget.find('.evi-widget-resend-permission-link-success').addClass('evi-widget-hidden');
        }
    };
    var showResendPermissionLink = function (widget) {
        widget.find('.evi-widget-resend-permission-link').removeClass('evi-widget-hidden');
        widget.find('.evi-widget-resend-permission-link-success').addClass('evi-widget-hidden');
    };
    var showBlacklistError = function (widget) {
        widget.find('.evi-widget-blacklisted-error').show();
    };
    var renderRule = function (rule) {
        var template = getTemplateName(rule.template, rule.status);
        var options = getOptions();
        if (template) {
            rule.email = rule.email || options.email;
            if (options.categories) {
                rule.categories = options.categories;
                rule.categories.forEach(function (category) {
                    category.lastModified = toLocaleDate(getLastModifiedForTypeAndValue(rule.actions, 'CATEGORY', category.id));
                    category.lastModifiedBy = getLastModifiedByForTypeAndValue(rule.actions, 'CATEGORY', category.id);
                    category.origin = getOriginForTypeAndValue(rule.actions, 'CATEGORY', category.id);
                    category.confirmed = getAttributeForTypeAndValue('confirmed', rule.actions, 'CATEGORY', category.id);
                    category.granted = getAttributeForTypeAndValue('granted', rule.actions, 'CATEGORY', category.id);
                    category.revoked = getAttributeForTypeAndValue('revoked', rule.actions, 'CATEGORY', category.id);
                    category.pending = getPendingForTypeAndValue(rule.actions, 'CATEGORY', category.id);
                });
            }
            computeLastModified(rule);
            computeLastModifiedBy(rule);
            computeOrigin(rule);
            computeAttribute('confirmed', rule);
            computeAttribute('granted', rule);
            computeAttribute('revoked', rule);
            computePending(rule);
            if (rule.actions) {
                rule.actions.sort(function (a, b) {
                    var titleA = a.data && a.data.title ? a.data.title.toUpperCase() : '';
                    var titleB = b.data && b.data.title ? b.data.title.toUpperCase() : '';
                    return titleA.localeCompare(titleB);
                });
            }
            if (rule.data) {
                rule.hasResendData = rule.data.text_resend_permission_link_before
                    || rule.data.text_resend_permission_link_after
                    || rule.data.link_resend_permission_link;
            }
            var widgetHTML = render(template, rule);
            var widget_3 = getWidget(rule);
            if (widget_3.length === 0) {
                self.append(widgetHTML);
            }
            else {
                widget_3.replaceWith(widgetHTML);
            }
            widget_3 = getWidget(rule);
            if (rule.email) {
                getEmailInput(rule).prop('disabled', true);
                widget_3.addClass('evi-widget-user-known');
            }
            else {
                widget_3.addClass('evi-widget-user-unknown');
            }
            var checkbox = $("#evi-widget-checkbox-" + rule.id);
            if (rule.template === 'CHECKBOX') {
                var checkthebox = (rule.status === 'HAVE_PERMISSION' && (rule.optInType === 'OPT_IN' || rule.optInType === 'OPT_OUT')) ||
                    (rule.status === 'WANT_PERMISSION' && (rule.optInType === 'OPT_OUT' || rule.optInType === 'REVERSE_OPT_OUT'));
                checkbox.prop('checked', checkthebox);
            }
            if (rule.template === 'GENERAL_NEWSLETTER_COCKPIT') {
                if (rule.status === 'HAVE_PERMISSION') {
                    checkbox.prop('checked', true);
                }
                if (hasPendingPermissionLinks(rule)) {
                    widget_3.find('.evi-widget-switch').addClass('evi-widget-switch-pending');
                }
                if (!isBlacklisted(rule)) {
                    renderResendLink(widget_3, rule);
                }
                checkbox.on('click', function (e) {
                    var element = $(e.delegateTarget);
                    var checked = element.prop('checked');
                    if (checked && !validate({ rule: rule })) {
                        e.preventDefault();
                        return;
                    }
                    if (checked) {
                        createPermissionLink({ rule: rule }, {
                            success: function (response) {
                                if (response && response.features && response.features.EMAIL === 'BLACKLISTED') {
                                    element.prop('checked', false);
                                    showBlacklistError(widget_3);
                                }
                                else {
                                    if (isDoubleOptInEnabled(rule)) {
                                        element.parent().addClass('evi-widget-switch-pending');
                                        renderResendLink(widget_3, rule, true);
                                    }
                                }
                            },
                            error: function () {
                                element.prop('checked', false);
                                widget_3.find('.evi-widget-last-modified').show();
                            }
                        });
                    }
                    else {
                        element.parent().removeClass('evi-widget-switch-pending');
                        hideResendPermissionLink(widget_3);
                        revokePermissionLink({ rule: rule }, {
                            error: function () {
                                element.prop('checked', true);
                                widget_3.find('.evi-widget-last-modified').show();
                            }
                        });
                    }
                    widget_3.find('.evi-widget-last-modified').hide();
                });
            }
            if (rule.template === 'CATEGORY_NEWSLETTER_COCKPIT') {
                var checkboxes = widget_3.find('.evi-widget-categories input');
                if (!isBlacklisted(rule)) {
                    renderResendLink(widget_3, rule);
                }
                checkboxes.on('click', function (e) {
                    var element = $(e.delegateTarget);
                    var checked = element.prop('checked');
                    if (checked && !validate({ rule: rule })) {
                        e.preventDefault();
                        return;
                    }
                    if (checked) {
                        createPermissionLink({ rule: rule, context: { categoryID: element.val() } }, {
                            success: function (response) {
                                if (response && response.features && response.features.EMAIL === 'BLACKLISTED') {
                                    element.prop('checked', false);
                                    showBlacklistError(widget_3);
                                }
                                else {
                                    if (isDoubleOptInEnabled(rule)) {
                                        element.parent().addClass('evi-widget-switch-pending');
                                        renderResendLink(widget_3, rule, true);
                                    }
                                }
                            },
                            error: function () {
                                element.prop('checked', false);
                                element.closest('li').find('.evi-widget-last-modified').show();
                            }
                        });
                    }
                    else {
                        element.parent().removeClass('evi-widget-switch-pending');
                        hideResendPermissionLink(widget_3);
                        revokePermissionLink({ rule: rule, context: { categoryID: element.val() } }, {
                            error: function () {
                                element.prop('checked', true);
                                element.closest('li').find('.evi-widget-last-modified').show();
                            }
                        });
                    }
                    element.closest('li').find('.evi-widget-last-modified').hide();
                });
                if (rule.actions) {
                    rule.actions.forEach(function (action) {
                        if (action.type === 'CATEGORY' && action.permissionLinks) {
                            action.permissionLinks.forEach(function (permissionLink) {
                                widget_3
                                    .find(".evi-widget-categories input[value=\"" + CSS.escape(permissionLink.value) + "\"]")
                                    .prop('checked', permissionLink.status !== 'REVOKED');
                            });
                        }
                    });
                }
            }
            if (rule.template === 'GENERAL_NEWSLETTER_COCKPIT_MULTI') {
                var checkboxes = widget_3.find('input.evi-widget-checkbox');
                if (!isBlacklisted(rule)) {
                    renderResendLink(widget_3, rule);
                }
                checkboxes.on('click', function (e) {
                    var element = $(e.delegateTarget);
                    var checked = element.prop('checked');
                    if (checked && !validate({ rule: rule })) {
                        e.preventDefault();
                        return;
                    }
                    if (checked) {
                        createPermissionLink({ rule: rule, actionId: element.val() }, {
                            success: function (response) {
                                if (response && response.features && response.features.EMAIL === 'BLACKLISTED') {
                                    element.prop('checked', false);
                                    showBlacklistError(widget_3);
                                }
                                else {
                                    if (isDoubleOptInEnabled(rule, element.val())) {
                                        element.parent().addClass('evi-widget-switch-pending');
                                        renderResendLink(widget_3, rule, true);
                                    }
                                }
                            },
                            error: function () {
                                element.prop('checked', false);
                                element.closest('li').find('.evi-widget-last-modified').show();
                            }
                        });
                    }
                    else {
                        element.parent().removeClass('evi-widget-switch-pending');
                        hideResendPermissionLink(widget_3);
                        revokePermissionLink({ rule: rule, actionId: element.val() }, {
                            error: function () {
                                element.prop('checked', true);
                                element.closest('li').find('.evi-widget-last-modified').show();
                            }
                        });
                    }
                    element.closest('li').find('.evi-widget-last-modified').hide();
                });
                if (rule.actions) {
                    rule.actions.forEach(function (action) {
                        widget_3.find("input[value=\"" + CSS.escape(action.id) + "\"]").prop('checked', action.status === 'HAVE_PERMISSION');
                    });
                }
            }
            if (rule.template === 'SIGN_OFF_FROM_ALL') {
                widget_3.find('a').on('click', function () {
                    revokePermissionLink({ rule: rule }, {
                        success: function () {
                            widget_3.parent().trigger('evi-widget:success');
                            window.location.reload();
                        }
                    });
                });
            }
            if (rule.template === 'BLACKLIST_COCKPIT') {
                extendBlacklistWidget(widget_3, rule);
            }
            widget_3.find('input[title="toggle"]').on('click', function () {
                widget_3.find('.evi-widget-description, .evi-widget-artists, .evi-widget-artist-collection, .evi-widget-categories, .evi-widget-footer, .evi-widget-consent, .evi-widget-resend-permission-link, .evi-widget-blacklisted-error, .evi-widget-resend-permission-link-success, .evi-widget-submit').toggleClass('evi-widget-toggle-hidden');
            });
            widget_3.find('form').submit({ rule: rule }, function (e) { return submit(e, rule); });
            if (isMultiArtistTicketAlarm(rule)) {
                if (!isBlacklisted(rule)) {
                    renderResendLink(widget_3, rule);
                }
                var search_1 = getArtistsInput(rule);
                search_1.on('keyup', function (e) { return searchArtists(e, rule); });
                search_1.on('focus', function () {
                    search_1.parent().addClass('evi-widget-artists-search-focus');
                });
                search_1.on('blur', function () {
                    search_1.parent().removeClass('evi-widget-artists-search-focus');
                });
                widget_3.find('.evi-widget-artists').on('click', function () { return search_1.focus(); });
                var existingArtistIds_1 = [];
                if (rule.actions) {
                    rule.actions.forEach(function (action) {
                        if (action.type === 'ARTIST' && action.permissionLinks) {
                            action.permissionLinks.forEach(function (permissionLink) {
                                if (permissionLink.status !== 'REVOKED') {
                                    existingArtistIds_1.push(permissionLink.value);
                                }
                            });
                        }
                    });
                }
                getArtists(rule, existingArtistIds_1);
            }
            if (options.agent) {
                widget_3.addClass('evi-widget-agent');
            }
        }
    };
    var renderWidget = function (response) {
        self.empty();
        self.append('<!-- EVI Widget version 3.8.4 -->');
        self.data('rules', response.rules);
        self.data('revision', response.revision);
        response.rules.forEach(function (rule) {
            rule.type = rule.template.toLowerCase().replace(/_/g, '-');
            rule.ruleData = rule.data;
            var emailBlacklisted = response.features && response.features.EMAIL && response.features.EMAIL === 'BLACKLISTED';
            rule.blacklisted = {
                email: emailBlacklisted
            };
            if (rule.template !== 'CHECKBOX' || (rule.template === 'CHECKBOX' && !emailBlacklisted)) {
                renderRule(rule);
            }
        });
        self.trigger('evi-widget:ready', { ruleCount: response.rules.length });
    };
    var getTemplateName = function (template, status) {
        if (template in widgets && status in widgets[template]) {
            return widgets[template][status];
        }
        return undefined;
    };
    var deriveStatus = function (rule) {
        if (rule.blacklisted.email) {
            return 'error';
        }
        if (isDoubleOptInEnabled(rule)) {
            return 'doi_sent';
        }
        return 'success';
    };
    var renderSuccess = function (rule, response) {
        var widget = getWidget(rule);
        var parent = widget.parent();
        // determine state of identity feature email
        rule.blacklisted = {
            email: response.features && response.features.EMAIL && response.features.EMAIL === 'BLACKLISTED'
        };
        var template = getTemplateName(rule.template, deriveStatus(rule));
        if (!isBlacklisted(rule)) {
            moveArtistsFromPreviewToResult(rule);
        }
        if (template) {
            widget.replaceWith(render(template, rule));
            if (!isBlacklisted(rule)) {
                renderResendLink(getWidget(rule), rule, true);
            }
            getWidget(rule).find('.evi-widget-reset').on('click', function (elem) {
                if (elem.target.getAttribute('data-status') === 'error') {
                    rule.email = null;
                }
                renderRule(rule);
                if (isMultiArtistTicketAlarm(rule)) {
                    getWidget(rule).addClass('evi-widget-artist-selected');
                }
            });
        }
        else if (isBlacklisted(rule)) {
            showBlacklistError(widget);
        }
        parent.trigger('evi-widget:success');
    };
    this.save = function (saveOptions) {
        var options = getOptions();
        var emailInput = options['mailInputSelector'];
        if (!emailInput) {
            handleError('No mailInputSelector defined in options');
            return;
        }
        var email = $(emailInput).val();
        if (!email) {
            handleError('email input is empty');
            return;
        }
        email = email.trim();
        if (!EmailValidator.validate(email)) {
            handleError("Invalid email: " + email);
            return;
        }
        var createRules = [];
        var revokeRules = [];
        self.data('rules').forEach(function (rule) {
            var checkbox = $("#evi-widget-checkbox-" + rule.id);
            if (checkbox.length) {
                var selected = checkbox.is(':checked');
                var subscription = (selected && ((rule.optInType === 'OPT_IN') || (rule.optInType === 'OPT_OUT'))) ||
                    (!selected && ((rule.optInType === 'REVERSE_OPT_IN') || (rule.optInType === 'REVERSE_OPT_OUT')));
                if ((rule.status === 'WANT_PERMISSION') && subscription) {
                    createRules.push(rule);
                }
                if ((rule.status === 'HAVE_PERMISSION') && !subscription) {
                    revokeRules.push(rule);
                }
            }
        });
        if (createRules.length > 0) {
            createPermissionLink({ rules: createRules, identityFeatureValue: email }, saveOptions);
        }
        if (revokeRules.length > 0) {
            revokePermissionLink({ rules: revokeRules, identityFeatureValue: email }, saveOptions);
        }
        if (createRules.length === 0 && revokeRules.length === 0 && saveOptions && saveOptions.success) {
            saveOptions.success();
        }
        return { created: createRules.length, revoked: revokeRules.length };
    };
    this.addArtist = function (artist) {
        self.data('rules').forEach(function (rule) {
            if (isMultiArtistTicketAlarm(rule)) {
                addArtist(rule, artist);
            }
        });
    };
    var moveArtistsFromPreviewToResult = function (rule) {
        var widget = getWidget(rule);
        var artistList = widget.find('.evi-widget-artist-list:visible');
        if (artistList.length === 2) {
            artistList.eq(0).find('li').each(function (_, elem) {
                removeArtist(widget, artistList.eq(0), $(elem).data('artist-id'));
                addArtistToList(rule, artistList.eq(1), {
                    artistId: $(elem).data('artist-id'),
                    name: $(elem).data('name')
                }, true);
            });
            renderResendLink(widget, rule, true);
        }
    };
    var submit = function (event, rule) {
        event.preventDefault();
        if (validate(event.data)) {
            createPermissionLink(event.data, { success: function (response) { return renderSuccess(rule, response); } });
        }
    };
    var init = function (options) {
        self.data('options', options);
        options.baseURL = options.baseURL.replace(/\/+$/, '');
        options.artistBaseURL = options.artistBaseURL || (options.baseURL + '/../../../artist/api');
        options.artistBaseURL = options.artistBaseURL.replace(/\/+$/, '');
        var payload = parseToken(options).payload;
        if (payload.tenant) {
            options.tenant = payload.tenant;
        }
        else {
            options.tenant = {
                systemId: payload.tenantSystemId,
                platformId: payload.tenantPlatformId
            };
        }
        options.agent = payload.agent;
        options.email = getEmailFromPayload(payload);
        getRules();
    };
    init(options);
};
$.fn['createEviWidget'] = function (options) {
    var eviWidget = new EviWidget(this, options);
    this.data('EviWidget', eviWidget);
    return this;
};
